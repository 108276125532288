function ixnCommand(ixn, command, args) {
    if ('undefined' == typeof args) {
        if (ixn instanceof Array) {
          ixn.forEach(i=>i[command]())
        } else {
          ixn[command]();
        }
    } else {
        if (ixn instanceof Array) {
          ixn.forEach(i=>i[command](...args))
        } else {
          ixn[command](...args);
        }
    }
}

export function addMarker(name, payload) {
  console.log('marking');
  if (typeof newrelic !== 'undefined'){
    console.log('really marking');
    var ixn = newrelic.interaction();
    console.dir(ixn);
    ixnCommand(ixn, 'setAttribute', ['EVENT_NAME', name]);
    newrelic.log('Marker added for '+name, {level: 'debug'});
    for (var key in payload) {
      if(payload.hasOwnProperty(key)){
        ixnCommand(ixn, 'setAttribute', [key, payload[key]])
        newrelic.log(`Marker added for ${key}:${payload[key]}`, {level: 'debug'});
      }
    }
    ixnCommand(ixn, 'save');

    setTimeout(() => {
      console.log("current interaction");
      console.dir(newrelic.interaction());
    }, 10000);

    setTimeout(() => {
      console.log("ending interaction");

      ixnCommand(newrelic.interaction(), 'end');
    }, 20000);
  }
}

export default addMarker;
